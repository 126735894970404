import React from 'react';

// External Components
import {
  Section,
  Box,
  Heading,
  Paragraph,
  FlexWrapper,
  Image,
  fullWidthMinusMargins
} from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer, ImageCover } from 'components';

// Assets
import arrow from 'assets/landingPage/header/arrow-right.svg';

// Animation
import { revealHeader } from './animation';
import { horizontalLoop } from 'sections/animations';

// Custom Context
import { useSiteLoad } from 'context/SiteLoadContext';

// hooks
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

// helper function
import { convertApiHtmlText } from 'sections/helper';

export const Header = ({
  data: {
    headline,
    handIllustration,
    yearIllustration,
    imageHeadline,
    images,
    awardImages
  },
  cmsData: { text }
}) => {
  const { isFirstLoad, setIsFirstLoad } = useSiteLoad();

  const sectionRef = React.useRef(null);

  // Animations
  React.useEffect(() => {
    setIsFirstLoad(false);
    return revealHeader(sectionRef, isFirstLoad);
  }, []);

  return (
    <Section as="header" id="landing-page-header" ref={sectionRef}>
      <Headline
        data={headline}
        handImg={handIllustration}
        yearImg={yearIllustration}
      />
      <ImageHeadline data={imageHeadline} />
      <ImagesWrapper images={images} />
      <TextBlock data={text?.html} />
      <AwardsSlider images={awardImages} />
      <AwardsList images={awardImages} />
    </Section>
  );
};

// Elements

const Headline = ({ data, handImg, yearImg }) => {
  const { locale } = usePageContext();
  return (
    <Paragraph
      className="landing-page-header__headline"
      type="p-700-100"
      variant="bold"
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/span 21', '1/span 21'],
        position: 'relative',
        textTransform: 'uppercase',
        zIndex: 1,
        // Animation value
        visibility: 'hidden',
        mt: locale === 'en' ? ['15%', '15%', '10%', 0, 0, 0] : null
      }}>
      <Span>{data[0].text}</Span>
      <HandIllustration img={handImg} />
      <Span>{data[1].text}</Span>
      <Span sx={{ textTransform: 'lowercase' }}>{data[2].text}</Span>
      <Arrow />
      <YearIllustration img={yearImg} />
    </Paragraph>
  );
};

const Span = ({ children, sx }) => (
  <Box as="span" sx={{ ...sx }}>
    {children}
  </Box>
);

const ImageHeadline = ({ data }) => (
  <Heading
    className="landing-page-header__image-headline"
    as="h1"
    type="h1-100-150"
    variant="bold"
    dangerouslySetInnerHTML={{
      __html: data
    }}
    sx={{
      gridColumn: [
        '1/13',
        '1/13',
        '1/span 19',
        '6/span 16',
        '9/span 14',
        '9/span 12'
      ],
      mt: ['43.90%', '26.76%', '29.79%', '17.54%', '24.86%', '25.32%'],
      textTransform: 'uppercase',
      // Animation value
      visibility: 'hidden'
    }}
  />
);

const ImagesWrapper = ({ images }) => (
  <Box
    className="landing-page-header__images-wrapper"
    sx={{
      display: 'grid',
      gridColumn: ['1/13', '1/13', '1/25', '6/25', '9/25', '9/25'],
      mt: ['2.51%', '3.10%', '2.26%', '1.47%', '2.41%', '1.89%'],
      position: 'relative'
    }}>
    {images.map((img, index) => (
      <ImageContainer
        className="project-image"
        key={`images-wrapper__item-${index}`}
        src={img.src}
        alt={img.alt}
        sx={{
          aspectRatio: [
            '1/1.56',
            '1/1.18',
            '1/0.59',
            '1/0.59',
            '1/0.59',
            '1/0.59'
          ],
          gridArea: '1/1',
          // Animation value
          transform: [
            'translateY(-90%) translateX(0)',
            'translateY(-90%) translateX(0)',
            'translateY(-135%) translateX(0)',
            'translateY(-100%) translateX(0)',
            'translateY(-100%) translateX(3%)',
            'translateY(-100%) translateX(3%)'
          ]
        }}
        imgProps={{
          objectFit: 'cover',
          objectPosition: '61% 50%',
          loading: 'eager'
        }}
      />
    ))}
    <ImageCover
      sx={{
        // Animation value
        transform: [
          'translateY(-88%)',
          'translateY(-88%)',
          'translateY(-132%)',
          'translateY(-98%)',
          'translateY(-98%) translateX(3%)',
          'translateY(-98%) translateX(3%)'
        ]
      }}
    />
  </Box>
);

const TextBlock = ({ data }) => (
  <Paragraph
    className="landing-page-header__paragraph"
    type="p-500-175"
    dangerouslySetInnerHTML={{
      __html: convertApiHtmlText(data)
    }}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '6/25', '9/25', '9/25'],
      mt: ['5.02%', '4.65%', '3.39%', '2.95%', '3.62%', '2.83%'],
      textAlign: 'justify'
    }}
  />
);

// Phone Portrait and Phone Portrait XL awards logos component
const AwardsSlider = ({ images }) => {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...wrapperRef.current.children];

    const killAnimation = horizontalLoop(elements, {
      paused: false,
      repeat: -1,
      speed: 0.3
    });

    return killAnimation;
  }, []);

  // We have to double the images, to prevent image disapearing in phone XL due to insuficient amount of images
  const doubledImages = [...images, ...images];
  return (
    <FlexWrapper
      ref={wrapperRef}
      className="landing-page-header__awards-slider"
      sx={{
        display: ['flex', 'flex', 'none', 'none', 'none', 'none'],
        gridColumn: ['1/13', '1/13', null, null, null, null],
        mt: ['22.57%', '15.51%', null, null, null, null],
        mx: fullWidthMinusMargins
      }}>
      {doubledImages.map((img, index) => (
        <ImageContainer
          key={`awards-slider__item-${index}`}
          src={img.src}
          alt=""
          imgProps={{ objectFit: 'contain' }}
          sx={{
            minWidth: ['40.1%', '23%', null, null, null, null],
            pr: ['9%', '3.7%', null, null, null, null]
          }}
        />
      ))}
    </FlexWrapper>
  );
};

// Tablet Portrait and upwards awards logos component
const AwardsList = ({ images }) => (
  <FlexWrapper
    className="landing-page-header__awards-list"
    sx={{
      display: ['none', 'none', 'flex', 'flex', 'flex', 'flex'],
      gridColumn: [null, null, '1/25', '3/25', '6/25', '9/25'],
      justifyContent: 'space-between',
      mt: [null, null, '12.44%', '11.78%', '10.86%', '9.44%']
    }}>
    {images.map((img, index) => (
      <ImageContainer
        key={`awards-list__item-${index}`}
        src={img.src}
        alt=""
        imgProps={{ objectFit: 'contain' }}
        sx={{
          flexBasis: [null, null, '16.06%', '17.68%', '15.19%', '13.21%']
        }}
      />
    ))}
  </FlexWrapper>
);

// Decorations

const HandIllustration = ({ img }) => {
  const { locale } = usePageContext();

  const enStyle = {
    position: [
      'absolute',
      'absolute',
      'absolute',
      'relative',
      'relative',
      'relative'
    ],
    m: [null, null, null, '0 -1% -5% 0.5%', '0 0% -13% 0%', '0 0 -5% 0.8%'],
    // position: 'relative',
    top: ['-10%', '-3%', '-3%', 0, 0, 0],
    left: ['56%', '60%', '53%', 0, 0, 0],
    width: ['30.5%', '25.8%', '24.8%', '12.6%', '17%', '12.53%']
  };
  const deStyle = {
    position: 'relative',
    m: [
      '0 0 -13%',
      '0 0 -9%',
      '0 0 -11% -1.5%',
      '0 -1% -5% 0.5%',
      '0 0% -6% -0.5%',
      '0 0 -5% 0.8%'
    ],
    width: ['30.5%', '25.8%', '24.8%', '12.6%', '15%', '12.53%']
  };

  const getStyle = () => (locale === 'en' ? enStyle : deStyle);
  return (
    <Image
      className="hand-illustration"
      src={img.publicURL}
      alt=""
      sx={{
        zIndex: -1,
        ...getStyle(),
        // Animation value
        visibility: 'hidden'
      }}
    />
  );
};

const YearIllustration = ({ img }) => (
  <Image
    className="year-illustration"
    src={img.publicURL}
    alt=""
    sx={{
      bottom: ['-10%', '-6%', '-4%', '-12%', '-14%', '-11%'],
      ml: [0, 0, '5%', '5%', '2%', '5.8%'], // ml instead of left value to ensure that it ends when the text does
      position: 'absolute',
      width: ['49%', '46.8%', '38.2%', '21.1%', '28%', '23.53%'],
      zIndex: -1,
      // Animation value
      visibility: 'hidden'
    }}
  />
);

const Arrow = () => (
  <Image
    className="arrow-illustration"
    src={arrow}
    alt=""
    sx={{
      bottom: ['0.5%', '2.5%', '2.5%', '0.5%', '2.5%', '1.5%'],
      ml: ['2%', '2%', '2.5%', '1%', '1%', '0.8%'], // ml instead of left value to ensure that it ends when the text does
      position: 'absolute',
      width: ['13%', '13%', '10%', '7.3%', '7.2%', '8%'],
      // Animation value
      visibility: 'hidden'
    }}
  />
);
