import React from 'react';

// External Components
import { fullWidthMinusMargins, Paragraph, Section } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer } from 'components';

// Helper
import { convertApiHtmlText } from 'sections/helper';

export const Factory = ({
  data: { image },
  cmsData: { factorytext, teamtext }
}) => {
  return (
    // Markup
    <Section id="factory">
      <Textblock data={teamtext.html} />
      <Image img={image} />
      <Caption data={factorytext.html} />
    </Section>
  );
};

// Elements

const Textblock = ({ data }) => (
  <Paragraph
    type="p-500-175"
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/span 18', '2/span 16'],
      textAlign: 'justify'
    }}>
    {convertApiHtmlText(data)}
  </Paragraph>
);

const Image = ({ img }) => (
  <ImageContainer
    className="factory__image"
    src={img}
    alt=""
    sx={{
      aspectRatio: ['1/1.163', '1.687/1', '1.7/1', '1.68/1', '1.7/1', '1.7/1'],
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2/span 22'],
      mt: ['14.42%', '11.44%', '3.25%', '8.72%', '2.40%', '2.05%'],
      mx: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0]
    }}
  />
);

const Caption = ({ data, sx, ...props }) => (
  <Paragraph
    type="p-600-150"
    variant="bold"
    sx={{
      textTransform: 'uppercase',
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2 / span 22'],
      mt: ['14.11%', '11.44%', '3.39%', '8.72%', '2.40%', '2.05%'],
      ...sx
    }}
    {...props}>
    {convertApiHtmlText(data)}
  </Paragraph>
);
