import React from 'react';

// External Components
import {
  fullWidthMinusMargins,
  GridWrapper,
  Heading,
  Paragraph
} from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer, Hyperlink } from 'components';

// helper
import { convertApiHtmlText } from 'sections/helper';

// Animations
import { revealSection } from './animations';

export const ProjectOverview = ({
  data: { images, hyperlink },
  cmsData: { headline, text, imagedescription, imagedescriptiontwo }
}) => {
  React.useEffect(() => {
    const killAnimation = revealSection();

    return killAnimation;
  }, []);
  return (
    <GridWrapper id="landing-page__project-overview" as="section">
      <Headline data={text.html} />
      <LeadText data={headline} />
      <ImageOne {...images[0]} />
      <ImageTwo {...images[1]} />
      <ImageTwoDescription description={imagedescription} />
      <ImageThree {...images[2]} />
      <ImageThreeDescription description={imagedescriptiontwo} />
      <Link {...hyperlink} />
    </GridWrapper>
  );
};

// Elements

const Headline = ({ data }) => {
  return (
    <Heading
      className="project-overview__headline"
      as="h2"
      type="h2-100-150"
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(data)
      }}
      sx={{
        gridColumn: [
          '1/span 11',
          '1/span 10',
          '1/span 18',
          '1/span 16',
          '1/span 12',
          '1/span 11'
        ],
        gridRow: [null, null, null, 1, 1, 1]
      }}
    />
  );
};

const LeadText = ({ data }) => (
  <Paragraph
    className="project-overview__background-text"
    type="p-1000-100"
    variant="bold"
    sx={{
      alignSelf: 'start',
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2/span 22'],
      // gridRow: [1, '1/span 2', '1/span 2', '1/span 2', '1/span 2', 1],
      mt: ['30.1%', '16.29%', '10.89%', '9.44%', '10.51%', '10.84%'],
      textAlign: 'center',
      textTransform: 'uppercase',
      gridRow: [null, null, null, 2, 2, 2]
    }}>
    {data}
  </Paragraph>
);

// Images

const ImageOne = ({ src }) => (
  <ImageContainer
    className="project-overview__image-one"
    src={src}
    alt=""
    sx={{
      alignSelf: 'start',
      aspectRatio: ['1/1.3', '1/0.77', '1/0.79', '1/0.77', '1/1.3', '1/1.3'],
      gridColumn: ['4/13', '3/13', '8/span 16', '11/25', '13/span 10', '15/25'],
      gridRow: [null, null, null, 3, 3, 3],
      mt: ['29.42%', '28.26%', '26.07%', '22.60%', '14.82%', '12.79%'],
      mr: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
      // Animation value
      overflow: 'hidden'
    }}
  />
);

const ImageTwo = ({ src }) => (
  <ImageContainer
    className="project-overview__image-two"
    src={src}
    alt=""
    sx={{
      gridColumn: [
        '1/13',
        '1/span 8',
        '1/span 12',
        '1/span 11',
        '1/span 10',
        '2/span 9'
      ],
      mt: ['12.54%', '35.23%', '32.82%', '20%', '-40%', '86.83%'],
      mr: [fullWidthMinusMargins[0], 0, 0, 0, 0, 0],
      aspectRatio: '1.32/1',
      gridRow: [null, null, null, 4, 4, 3],
      // Animation value
      overflow: 'hidden'
    }}
  />
);

const ImageTwoDescription = ({ description }) => (
  <Description
    className="project-overview__image-two-description"
    data={description}
    sx={{
      gridColumn: [
        '1/span 11',
        '1/span 8',
        '1/span 12',
        '1/span 11',
        '1/span 10',
        '2/span 9'
      ],
      gridRow: [null, null, null, 5, 5, 4]
    }}
  />
);

const ImageThree = ({ src }) => (
  <ImageContainer
    className="project-overview__image-three"
    src={src}
    alt=""
    sx={{
      gridColumn: [
        '2/13',
        '5/span 7',
        '12/span 11',
        '14/span 10',
        '14/span 8',
        '13/span 8'
      ],
      mt: ['17.13%', '24.25%', '20.17%', '-20%', '-15%', 0],
      aspectRatio: '1.3/1',
      gridRow: [null, null, null, 6, 6, 5],

      // Animation value
      overflow: 'hidden'
    }}
  />
);

const ImageThreeDescription = ({ description }) => (
  <Description
    className="project-overview__image-three-description"
    data={description}
    sx={{
      gridColumn: [
        '2/13',
        '5/span 7',
        '12/span 11',
        '14/span 10',
        '14/span 8',
        '13/span 8'
      ],
      gridRow: [null, null, null, 7, 7, 6]

      // gridRow: [5, 5, 5, 5, 5, 4]
    }}
  />
);

// All projects hyperlink

const Link = ({ name, to }) => (
  <Hyperlink
    text={name}
    to={to}
    sx={{
      alignSelf: 'end',
      gridColumn: [
        '2/span 10',
        '5/span 7',
        '1/span 10',
        '1/span 9',
        '1/span 11',
        '2/span 9'
      ],
      mt: ['24.16%', '24.25%', 0, 0, 0, '9.58%'],
      gridRow: [null, null, 6, 6, 6, 7]
    }}
  />
);

// Reusable Component

const Description = ({ data, sx, className }) => (
  <Paragraph
    className={`project-overview__image-description ${className}`}
    type="p-100-150"
    sx={{
      mt: ['1.34em', '1.23em', '1.24em', '1.34em', '1.15em', '1.23em'],
      ...sx
    }}>
    {data}
  </Paragraph>
);
