import { gsap } from 'gsap';

export const revealHeader = (sectionRef, isFirstSiteLoad) => {
  const gsapSelector = gsap.utils.selector(sectionRef);

  const headlineWrapper = gsapSelector('.landing-page-header__headline');
  const hand = gsapSelector('.hand-illustration');
  const arrow = gsapSelector('.arrow-illustration');
  const year = gsapSelector('.year-illustration');
  const imageHeadline = gsapSelector('.landing-page-header__image-headline');
  const [imgOne, imgTwo, imgThree, imgFour, imgFive] = gsap.utils.toArray(
    gsapSelector('.project-image')
  );
  const cover = gsapSelector('.animated-image__cover');

  // Animation defaults
  gsap.defaults({ ease: 'Power4.easeOut' });

  // Animation of header headline with hand and arrow
  let headlineTl = gsap
    .timeline({})
    .fromTo(
      headlineWrapper,
      { y: 80 },
      {
        autoAlpha: 1,
        y: 0,
        duration: 1.6
      },
      '<'
    )
    .fromTo(
      hand,
      { y: 180, scale: 0 },
      { autoAlpha: 1, y: 0, scale: 1, duration: 1 },
      '<'
    )
    .fromTo(
      [arrow, year],
      { x: -40 },
      { autoAlpha: 1, x: 0, duration: 1, stagger: 0.2 },
      '<0.1'
    );

  if (!isFirstSiteLoad) {
    // simpel header animation (without the image sliding)
    gsap.set(cover, { scaleY: 0 });
    gsap.set([imgTwo, imgThree, imgFour, imgFive], { xPercent: -200 });
    gsap.set(imgOne, { x: 0, y: 0 });
    gsap.set(imageHeadline, { autoAlpha: 1, y: 0 });
    return () => headlineTl.kill();
  }

  // only on first site load:
  // Full header animation (image sliding + headline animation)
  let tl = gsap.timeline({ delay: 1 });

  tl.to(cover, { scaleY: 0, transformOrigin: 'top center', duration: 0.8 })
    .to([imgTwo, imgThree, imgFour, imgFive], {
      xPercent: -200,
      stagger: -0.2,
      duration: 1,
      ease: 'Power4.easeIn'
    })
    .to(imgOne, { x: 0, duration: 1.1, ease: 'Power4.easeInOut' }, '>-1')
    .to(imgOne, { y: 0, duration: 1.6 }, '<1.2')
    .add(headlineTl, '<')
    .fromTo(
      imageHeadline,
      { y: 16 },
      { autoAlpha: 1, y: 0, duration: 1.6 },
      '<1'
    );

  return () => tl.kill();
};
