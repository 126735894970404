import React from 'react';
import { graphql } from 'gatsby';
// External Components
import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import {
  useLandingPageData,
  useCmsLandingPageData,
  Header,
  ProjectOverview,
  Services,
  Factory,
  News
} from 'sections/landingPage';
import { SectionSpacer } from 'components';

const Home = ({ data }) => {
  const { seo, header, project, factory, news } = useLandingPageData();
  const prismicNewsData = data.news.news;
  const {
    awards,
    inspiration,
    team,
    services: cmsServices
  } = useCmsLandingPageData();

  return (
    <>
      <SEO {...seo} />
      <SectionSpacer
        spacing={['12.54%', '7.76%', '5.66%', '5.81%', '4.00%', '3.13%']}
      />
      <Header data={header} cmsData={awards.currentLanguageData} />
      <SectionSpacer
        spacing={['50.17%', '46.53%', '64.91%', '23.25%', '40.04%', '31.26%']}
      />
      <ProjectOverview
        data={project}
        cmsData={inspiration.currentLanguageData}
      />
      <SectionSpacer
        spacing={['75.25%', '46.53%', '56.57%', '23.25%', '24.03%', '31.26%']}
      />
      <Services cmsData={cmsServices.currentLanguageData} />
      <SectionSpacer
        spacing={['75.25%', '46.53%', '56.57%', '34.87%', '24.03%', '31.26%']}
      />
      <Factory data={factory} cmsData={team.currentLanguageData} />
      <SectionSpacer
        spacing={['75.25%', '46.53%', '33.94%', '34.87%', '40.04%', '31.26%']}
      />
      <News staticData={news} news={prismicNewsData} />
      <SectionSpacer
        spacing={['75.25%', '46.53%', '33.94%', '34.87%', '40.04%', '31.26%']}
      />
    </>
  );
};

export default injectIntl(Home);

// Page Query
export const query = graphql`
  query getPrismicBlogPosts($prismicLang: String) {
    news: allPrismicNews(
      filter: { lang: { eq: $prismicLang } }
      sort: { order: DESC, fields: last_publication_date }
    ) {
      news: nodes {
        data {
          title {
            text
          }
          project_link {
            uid
            lang
            document {
              ... on PrismicProject {
                uid
                lang
                alternate_languages {
                  id
                  lang
                  uid
                }
                data {
                  name {
                    text
                  }
                  location
                  year
                  category
                }
              }
            }
          }
        }
      }
    }
  }
`;
