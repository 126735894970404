import React from 'react';

// External Components
import { Section, Heading, GridWrapper, Box } from '@thepuzzlers/pieces';
import { NavigationLink, useIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { ListWrapper, RedArrow } from 'components';

// Animations
import { revealSection } from './animations';

// Main Components
export const News = ({ staticData: { headline }, news }) => {
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...sectionRef.current.children];

    const killAnimation = revealSection(sectionRef, elements);

    return killAnimation;
  }, []);

  return (
    <Section id="blog" ref={sectionRef}>
      <Headline text={headline} />
      <NewsList news={news} />
    </Section>
  );
};

const Headline = ({ text }) => {
  return (
    <Heading
      as="h2"
      type="h2-500-100"
      variant="bold"
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2/25'],
        textTransform: 'uppercase'
      }}>
      {text}
    </Heading>
  );
};

const NewsList = ({ news }) => {
  const { locale } = useIntl();

  return (
    <ListWrapper
      animateLine={false}
      sx={{
        gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2/25'],
        mt: ['10.03%', '7.76%', '7.92%', '5.81%', '5.61%', '4.57%Is']
      }}>
      {news.map((newsItem) => {
        // we always use the english uid for the url,
        // this makes sure german and english translations have the same url and that the language switch works properly
        const projectSlug =
          locale === 'en'
            ? newsItem.data.project_link.document.uid
            : newsItem.data.project_link.document.alternate_languages[0].uid;

        const pathName = locale === 'en' ? '/projects/' : '/projekte/';

        return (
          <NewsNavigationLink
            key={newsItem.data.project_link.document.uid}
            data={newsItem.data}
            to={pathName + projectSlug}
          />
        );
      })}
    </ListWrapper>
  );
};

const NewsNavigationLink = ({ data: { project_link, title }, to }) => {
  return (
    // Wrapper box for animation to work
    <Box
      sx={{
        overflow: 'hidden'
      }}>
      <NavigationLink to={to}>
        <GridWrapper
          variant="inside.autoColumns"
          // Animation Value
          initial="initial"
          whileHover="hover"
          sx={{
            py: ['12.54%', '7.76%', '6.79%', '4.65%', '4.00%', '3.26%']
          }}>
          <Heading
            as="h3"
            type="h3-300-110"
            variant="bold"
            sx={{
              gridColumn: [
                '1 / span 11',
                '1 / span 11',
                '1 / span 22',
                '1 / span 22',
                '1 / span 22',
                '1 / span 16'
              ]
            }}>
            {title.text}
          </Heading>
          <Heading
            as="h3"
            type="h3-300-110"
            sx={{
              gridColumn: [
                '1 / span 11',
                '1 / span 11',
                '1 / span 22',
                '1 / span 22',
                '1 / span 22',
                '18 / span 5'
              ],
              mt: ['0.67em', '0.6em', '0.54em', '0.75em', '0.82em', 0]
            }}>
            {project_link.document.data.location}
          </Heading>
          <RedArrow
            sx={{
              gridColumn: [
                '12/13',
                '12/13',
                '23/25',
                '24/25',
                '24/25',
                '23 / span 1'
              ],
              justifySelf: 'end',
              alignSelf: ['end', 'end', 'end', 'end', 'end', 'center'],
              width: ['98%', '71%', '44%', '100%', '96%', '76%']
            }}
          />
        </GridWrapper>
      </NavigationLink>
    </Box>
  );
};
