import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useLandingPageData = () => {
  const { locale } = usePageContext();

  const data = useStaticQuery(graphql`
    query {
      en: landingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline {
            text
          }
          handIllustration {
            publicURL
          }
          yearIllustration {
            publicURL
          }
          imageHeadline
          images {
            alt
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
          awardImages {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
          }
        }
        project {
          images {
            description
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          hyperlink {
            name
            to
          }
        }
        factory {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        news {
          headline
        }
      }
      de: deLandingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline {
            text
          }
          handIllustration {
            publicURL
          }
          yearIllustration {
            publicURL
          }
          imageHeadline
          images {
            alt
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          awardImages {
            src {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG, quality: 100)
              }
            }
          }
        }
        project {
          images {
            description
            src {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          hyperlink {
            name
            to
          }
        }
        factory {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        news {
          headline
        }
      }
    }
  `);

  return data[locale];
};
