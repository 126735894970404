import React from 'react';

// External Components
import { Heading, Paragraph, Section, Box } from '@thepuzzlers/pieces';

// Local Components
import { ImageContainer, Hyperlink, ListWrapper } from 'components';

// Animations
import { revealSection } from './animations';

// Helper
import { convertApiHtmlText } from 'sections/helper';

export const Services = ({
  cmsData: { headline, description, highlighted_services, link_label, link_to }
}) => {
  const sectionRef = React.useRef(null);

  React.useEffect(() => {
    const elements = [...sectionRef.current.children];

    const killAnimation = revealSection(sectionRef, elements);

    return killAnimation;
  }, []);

  return (
    // Markup
    <Section id="landing-page__services" ref={sectionRef}>
      <Headline data={headline} />
      <Description data={description} />
      <ServicesList cmsData={highlighted_services} />
      <Link name={link_label} to={link_to} />
    </Section>
  );
};

// Elements

const Headline = ({ data }) => (
  <Heading
    as="h2"
    type="h2-500-100"
    variant="bold"
    sx={{
      gridColumn: [
        '1/13',
        '1/span 10',
        '1/span 16',
        '1/span 12',
        '1/span 13',
        '2/span 11'
      ],
      textTransform: 'uppercase'
    }}>
    {data}
  </Heading>
);

const Description = ({ data }) => (
  <Paragraph
    type="p-300-150"
    sx={{
      gridColumn: [
        '1/13',
        '1/span 9',
        '1/span 17',
        '1/span 16',
        '1/span 13',
        '18/span 6'
      ],
      mt: ['5.02%', '2.60%', '4.83%', '4.38%', '4.47%', 0]
    }}>
    {data}
  </Paragraph>
);

const ServicesList = ({ cmsData }) => (
  <ListWrapper
    animateLine={false}
    sx={{
      gridColumn: ['1/13', '1/13', '1/25', '1/25', '1/25', '2/span 22'],
      mt: ['12.54%', '9.31%', '9.05%', '6.97%', '6.41%', '4.78%']
    }}>
    {cmsData.map((service, index) => (
      <ListItem key={index} data={service} />
    ))}
  </ListWrapper>
);

const ListItem = ({ data: { name } }) => (
  <Box
    className="list-wrapper__list-item"
    // Animation values
    initial="initial"
    whileHover="hover"
    sx={{
      position: 'relative',
      py: ['10.03%', '7.76%', '5.94%', '4.65%', '4.61%', '4.10%']
    }}>
    {/* Added box to hide overflow on text reveal animation */}
    <Box
      className="list-item__title-wrapper"
      sx={{
        overflow: 'hidden',
        width: ['92%', '100%', '100%', '100%', '100%', '100%']
      }}>
      <Heading
        as="h3"
        type="h3-500-100"
        variant="bold"
        dangerouslySetInnerHTML={{
          __html: convertApiHtmlText(name.html)
        }}
        sx={{
          textTransform: 'uppercase',
          '& > span': { color: 'red' }
        }}
      />
    </Box>
  </Box>
);

const Link = ({ name, to }) => <Hyperlink text={name} to={to} />;
