import { graphql, useStaticQuery } from 'gatsby';

// Helper
import { useGetCurrentLanguageCmsData } from 'sections/helper';

export const useCmsLandingPageData = () => {
  const { getCurrentLanguageData } = useGetCurrentLanguageCmsData();

  const {
    allRestApiPublicPagesLandingpageEntries,
    allRestApiPublicContentTypesHighlightedServiceEntries
  } = useStaticQuery(graphql`
    query {
      allRestApiPublicPagesLandingpageEntries {
        nodes {
          data {
            key
            contentTypes {
              key
              name
              hasEntry
              entries {
                entry {
                  factorytext {
                    html
                  }
                  headline
                  imagedescription
                  imagedescriptiontwo
                  teamtext {
                    html
                  }
                  text {
                    html
                  }
                  description
                  highlighted_services {
                    data {
                      name {
                        html
                      }
                    }
                    id
                  }
                  link_label
                  link_to
                }
                translations {
                  en {
                    headline
                    imagedescription
                    imagedescriptiontwo
                    factorytext {
                      html
                    }
                    teamtext {
                      html
                    }
                    text {
                      html
                    }
                    description
                    link_label
                    link_to
                  }
                }
              }
            }
          }
        }
      }
      allRestApiPublicContentTypesHighlightedServiceEntries {
        nodes {
          data {
            id
            entry {
              name {
                html
              }
            }
            translations {
              en {
                name {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  const landingPageContents =
    allRestApiPublicPagesLandingpageEntries.nodes[0].data.contentTypes;

  const highlightedServicesData =
    allRestApiPublicContentTypesHighlightedServiceEntries.nodes[0].data;

  const getHighlightedServiceById = (id) =>
    highlightedServicesData.find((service) => service.id === id);

  const sectionsWithCurrentLanguageData = landingPageContents.map((content) => {
    if (content.key !== 'services')
      return {
        ...content,
        currentLanguageData: getCurrentLanguageData(content.entries[0])
      };
    return {
      ...content,
      currentLanguageData: {
        ...getCurrentLanguageData(content.entries[0]),
        highlighted_services: content.entries[0].entry.highlighted_services.map(
          (service) => {
            return getCurrentLanguageData(
              getHighlightedServiceById(service.id)
            );
          }
        )
      }
    };
  });

  return {
    header: {},
    awards: sectionsWithCurrentLanguageData[1],
    inspiration: sectionsWithCurrentLanguageData[2],
    team: sectionsWithCurrentLanguageData[3],
    services: sectionsWithCurrentLanguageData[4]
  };
};
