import { gsap } from 'gsap';

import { revealListWrapperItems } from 'sections/animations';

export const revealSection = (section, elements) => {
  // Get elements to animate
  const text = [elements[0], elements[1]];
  const listItems = [...elements[2].children];
  const hyperlink = elements[3];
  const titles = [];
  const lines = [];

  listItems.map((item, index) => {
    index % 2 !== 0
      ? titles.push(item.children[0].children[0])
      : lines.push(item);
  });

  // Tween defaults
  gsap.defaults({ ease: 'Power3.easeOut' });

  // Timeline
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: section.current,
      start: 'top 70%'
    }
  });

  tl.from(text, {
    autoAlpha: 0,
    y: 40,
    duration: 1.5,
    stagger: 0.3
  }).add(revealListWrapperItems(lines, titles, hyperlink), 0);

  return () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };
};
