import { gsap } from 'gsap';

export const revealSection = () => {
  const backgroundText = '.project-overview__background-text';

  const paragraphAnimation = gsap.from('.project-overview__headline', {
    yPercent: 60,
    duration: 1.4,
    opacity: 0,
    scrollTrigger: {
      trigger: '.project-overview__headline',
      start: 'top 90%'
    }
  });

  const headlineAnimation = gsap.from(backgroundText, {
    yPercent: 30,
    duration: 1.2,
    opacity: 0,
    scrollTrigger: {
      trigger: backgroundText,
      start: 'end 90%'
    }
  });

  // Image one animation timeline
  const imageOneAnimationTimeline = gsap.from(
    '.project-overview__image-one',
    {
      opacity: 0,
      yPercent: 40,
      duration: 2,
      scrollTrigger: {
        trigger: backgroundText,
        start: 'top 60%'
      }
    },
    '<'
  );

  // Image two animation timeline
  const imageTwoAnimationTimeline = gsap
    .timeline({
      scrollTrigger: {
        trigger: backgroundText,
        start: 'bottom center'
      }
    })
    .from(
      [
        '.project-overview__image-two',
        '.project-overview__image-two-description'
      ],
      {
        opacity: 0,
        y: 300,
        duration: 1.5
      },
      '<'
    );

  // Image three animation timeline
  const imageThreeAnimationTimeline = gsap
    .timeline({
      scrollTrigger: {
        trigger: '.project-overview__image-three',
        start: 'top 90%'
      }
    })
    .from(
      [
        '.project-overview__image-three',
        '.project-overview__image-three-description'
      ],
      {
        opacity: 0,
        y: 60,
        duration: 1.5
      },
      '<'
    );

  return () => {
    paragraphAnimation.kill();
    headlineAnimation.kill();
    imageOneAnimationTimeline.kill();
    imageTwoAnimationTimeline.kill();
    imageThreeAnimationTimeline.kill();
  };
};
